<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" style="overflow:auto;">
        <div style="padding:20px 0px;width:95%;position:relatvie;margin:0 auto;" >
            <div style="text-align:center;position:relative;margin-bottom:30px;">
                <span style="font-size:26px;">新增商品</span>
                <!-- <span style="color:#999;position:absolute;right:100px;top:20px;">单据编号: </span> -->
            </div>
        </div>

        <div>
       <!-- 创建 -->
        <div style="width:96%;margin:0 auto;background:#fff;" :style="{height:__tableHeight__+'px'}">
            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">基础信息</div>
                </div>
                <!-- 基础信息 -->
                <el-row >
                    <el-form :inline="true" label-width="80px">
                        <el-form-item label="商品名称">
                            <el-input placeholder="请输入内容" v-model="saveData.name" class="baseInput"></el-input>
                        </el-form-item>
                        <el-form-item label="销售价">
                            <el-input placeholder="请输入内容" v-model="saveData.price_copy" class="baseInput"></el-input>
                        </el-form-item>
                        <el-form-item label="商品编码">
                            <el-input placeholder="请输入内容" v-model="saveData.product_code" class="baseInput"></el-input>
                        </el-form-item>
                        <el-form-item label="条形码">
                            <el-input placeholder="请输入内容" v-model="saveData.bar_code" class="baseInput"></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类">
                            <el-cascader
                            style="display:inline-block;"
                            v-model="saveData.classify_id"
                            width="130"
                            :options="source.classify"
                            :show-all-levels="false"
                            :props="{ 
                                expandTrigger: 'hover',
                                children:'child',
                                emitPath:false,
                                value:'id',
                                label:'name'
                            }"></el-cascader>
                        </el-form-item>
                        <el-form-item label="保质期(月)">
                            <el-input placeholder="请输入内容" v-model="saveData.quality_guarantee_period" class="baseInput">
                            </el-input>                            
                        </el-form-item>
                        <el-form-item label="上架时间">
                            <el-date-picker
                            v-model="saveData.launch_date"
                            type="date"
                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="商品标签">
                            <el-select 
                                multiple
                                placeholder="商品标签" v-model="saveData.label_id" >
                                <el-option 
                                :label="item.name"
                                :value="item.id"
                                v-for="(item,key) in source.label" :key="key"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="商品单位">
                            <el-select placeholder="商品单位" v-model="saveData.unit_id" >
                                <el-option 
                                :label="item.name"
                                :value="item.id"
                                v-for="(item,key) in source.unit" :key="key"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商品状态">
                            <el-switch v-model="saveData.status"></el-switch>
                        </el-form-item>
                        
                    </el-form>
                </el-row>
            </div>

            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">规格信息</div>
                </div>
                <el-form :inline="true" label-width="100px">
                    <el-form-item label="选择属性">
                        <el-select 
                            multiple
                            @change="desposeChange"
                            placeholder="商品规格" v-model="dispose" >
                            <el-option 
                            :label="item.name"
                            :value="item.id"
                            v-for="(item,key) in source.attribute" :key="key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规格码前缀">
                        <el-input v-model="prefix1"></el-input>
                    </el-form-item>
                    <el-form-item label="规格码后缀">
                        <el-input v-model="prefix2"></el-input>
                    </el-form-item>

                </el-form>
                <!-- 规格信息 -->
                <div class="attr_row" v-for="(item,key) in  disposeInfoArr" :key="key" v-show="item.enable_status === '开启'">
                   <span>{{item.name}}</span> 
                   <span v-for="(i,k) in item.dispose_info" 
                        :key="k" 
                        style="margin:0 30px 0 10px;color:#fff;display:inline-block;">
                        <el-button 
                        size="small"
                        :style="{background:i.enable?'rgba(40, 208, 148, 1)':'rgba(204, 204, 204, 1)',color:i.enable?'rgba(255, 255, 255, 1)':'rgba(55, 55, 55, 1)'}"
                        @click="changeEnable(i)">{{i.value}}</el-button>
                        <span style="color:#aaa;"> 简称 
                            <el-input @change="changeEnable()" v-model="i.nickname" size="small" style="width:60px;"></el-input>
                        </span>
                   </span>
                   <el-button size="small" style="margin:0 0 0 60px;" type="primary" @click="addNewDispose(item)">添加新属性</el-button>
                </div>
                
                <div class="attr_row" style="margin:0 0 30px 0;">
                    <table class="mytable" style="width:100%;">
                        <thead class="thead">
                            <tr class="rows ">
                                <td class="cols" v-for="(item,key) in  disposeInfoArr" :key="key">{{item.name}}</td>
                                <td class="cols">图片</td>
                                <td class="cols">规格编码</td>
                                <td class="cols">规格条形码</td>
                                <td class="cols">零售价（元）</td>
                                <td class="cols">最高库存预警</td>
                                <td class="cols">最低库存预警</td>
                            </tr>
                        </thead>
                        <tbody class="tbody" v-if="disposePerm.length>0">
                            <tr class="rows " v-for="(i,k) in disposePerm" :key="k">
                                <td class="cols" v-for="(item,key) in disposeInfoArr" :key="key">
                                    {{getAttrName(item,i)}}
                                </td>
                                <td class="cols">
                                    <div class="cols_upload " >
                                        <el-upload
                                        style="width:40px;height:40px;position:absolute;top:0px;left:0px;opacity:0;"
                                        :show-file-list="false"
                                        :action="fileUrl"
                                        :headers="authorizationBearer"
                                        :on-success="changePic">
                                        <el-button @click="changeItem(i)"
                                        type="primary" 
                                        style="width:40px;height:40px;position:absolute;top:0px;left:0px;opacity:0;"></el-button>
                                        </el-upload>
                                        <i class="el-icon-plus" v-if="i.pic==''"></i>
                                        <img v-else :src="baseUrl+'/'+i.pic" alt="">
                                    </div>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.specification_code"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.specification_bar_code"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.retail_price_copy"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.max_stock"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.min_stock"></el-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">商品图片(第一张为主图)</div>
                </div>

                <div class="attr_row">
                    <div class="cols_upload big"  v-for="(item,key) in saveDataTmp.pic" :key="key">
                        <img :src="baseUrl+'/'+item.pic" alt="">
                        <div class="pic_modal"  >
                            <!-- 看大图 -->
                            <i class="el-icon-zoom-in" @click="showPreview(item)"></i> 
                            <!-- 删除 -->
                            <i class="el-icon-delete-solid" @click="deleteImg(key)"></i> 
                        </div>
                    </div>
                    <div class="cols_upload big">
                        <el-upload
                            :action="fileUrl"
                            multiple
                            :headers="authorizationBearer"
                            :show-file-list="false"
                            :on-success="uploadSuccess">
                            <i class="el-icon-plus" style="font-size:30px;"></i>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">商品详情</div>
                </div>
                <div class="attr_row">
                    <!-- 商品详情 -->
                    <div id="goods_detail">

                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button   @click="exit();">关 闭</el-button>
                <el-button type="primary"  @click="save();">保 存</el-button>
            </div>
        </div> 

        <el-dialog
        :modal="false"
        title="大图"
        :visible.sync="dialogVisible"
        width="600px">
        <div style="max-width:800px;max-height:500px;">
            <img style="width:100%;" :src="dialogImageUrl" alt="">
        </div>
        </el-dialog>

    </div>
        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="新增属性"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare  :id="currentId" @fleshList="flush" @shareExit="flush"></createShare>
        </el-dialog>
    </div>
</template>

<script>
import createShare from '../attr/creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
import methods from "./create";
import data from "./createData";

export default {
    props:{
        
    },
    inject: ['selectSide','reload'], 
    data(){
        return data;
    },
    destroyed(){
        this.goodsList = [];
        this.enclosure = [];
        this.saveDataTmp = {
            pic: [],
            dispose: []
        };
        this.disposePerm = [];
        this.disposeInfoArr = [];
        this.source.attribute = [];
        this.dispose = [];

        this.sumData = {
            sumNum: 0,
            sumPrice: 0,
            allSumPrice: '0.00',
        };
        this.saveData = tools.copy(this.saveDataInit);
    },
    components:{
        createShare,shareListFlag
    },
    methods:methods,
    mounted(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }
        //
        this.id = this.$route.query.id;
        //初始化数据
        this.init();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
  .add_container{
      min-height:100px;
  }


  .baseInput{
      max-width: 240px;margin:0 30px 15px 0;
  }
  .label_{
    background-color: #F5F7FA;
    color: #909399;
    vertical-align: middle;
    position: relative;
    border: 1px solid #DCDFE6;
    white-space: nowrap;
    height: 30px;
    line-height:31.8px;
    padding:0 5px 0 5px ;
    display: inline-block;
  }
.label2{
    background-color: #F5F7FA;
    color: #909399;
    vertical-align: middle;
    position: relative;
    border: 1px solid #DCDFE6;
    white-space: nowrap;
    height: 30px;
    line-height:31.8px;
    padding:0 20px 0 20px ;
    display: inline-block;
    margin-left:30px;
  }
.attr_row{
    margin:20px 0;
}
.cols_input{
    width:100px;
}
.cols_upload.big{
    width:148px;height:148px;line-height:148px;display: inline-block;overflow: hidden;margin-left:10px;
    .pic_modal{
        width:80px;height:30px;line-height: 30px;background:#000;opacity: 0.7;
        position:absolute;z-index: 8;top:60px;left:30px;
        i{
            font-size:18px;display: inline-block;color:#fff;
        }
        i.el-icon-delete-solid{
            margin-left:15px;
        }
    }
}
.cols_upload{
    position:relative;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
}
.cols_upload img{
    width:100%;
    height:100%;
}

</style>