import tools from "../../../extends/tools";
export default {
    createShareFlag: false,
    currentId: '',
    currentItem: [],
    // 条形码前缀
    prefix1: '',
    prefix2: '',

    id: 0,
    fileUrl: tools.baseFileUpload(),
    baseUrl: tools.baseURL(),
    currentDespose: null,
    dialogVisible: false,
    saveComplete: false,
    dispose: [],
    disposeInfoArr: [],
    disposePerm: [], //生成的排列组合数据，全排列
    uploadImages: [],
    authorizationBearer: tools.getToken(),
    dialogImageUrl: '',
    dialogVisible: false,
    //属性列表
    source: {
        classify: [],
        label: [],
        unit: [],
        attribute: []
    },
    // saveData1: {
    //     product_code: '',
    //     bar_code: '',
    //     name: '',
    //     classify_id: '',
    //     quality_guarantee_period: '',
    //     launch_date: '',
    //     label_id: '',
    //     unit_id: '',
    //     status: true,
    //     dispose: '',
    //     pic: '',
    //     dialogImageUrl: '',
    //     dialogVisible: false,
    //     details: ''
    // },
    saveDataInit: {},
    saveDataTmp: {
        pic: [],
        dispose: [],
    },
    saveData: {
        product_code: '',
        // 基准价格
        price: 0,
        price_copy: 0,
        bar_code: '',
        name: '',
        classify_id: '',
        quality_guarantee_period: 0,
        launch_date: tools.getDate(),
        label_id: [],
        unit_id: '',
        status: true,
        dispose: [],
        pic: [],
        details: ''
    },

    editor: null,

    format: 'YYYY-MM-DD HH:mm:ss'
}